import React from 'react'
import { MdKeyboardArrowUp } from 'react-icons/md';
import { Link, animateScroll as scroll } from 'react-scroll';
import { useMainContext } from '../context/mainContext';

const ToTop = () => {

    const { state: { toTopShow, t } } = useMainContext();

    return (
        <Link to="home" spy={true} smooth={true} offset={-70} duration={500}  className={`${ toTopShow ? 'fixed' : "hidden" } min-[1260px]:right-[44px] min-[1260px]:bottom-[31px] right-5 bottom-6 z-[100]`}>
                <button className='p-2 min-[1260px]:border-t-2 border-gray-700 dark:border-gray-400 rounded-full text-black  bg-white min-[1260px]:bg-transparent shadow-lg min-[1260px]:shadow-none' aria-label={t('backToTop')} title={t('backToTop')}>
                    <MdKeyboardArrowUp className='text-black md:dark:text-white text-3xl min-[1260px]:animate-bounce' />
                </button>
        </Link>
    );
}

export default ToTop;