import React from 'react'

const Loading = () => {
  return (
    <div className='flex justify-center items-center w-screen h-screen overflow-hidden' style={{backgroundImage: 'radial-gradient(circle farthest-corner at center, #3C4B57 0%, #1C262B 100%)'}}>
        <div className="loader absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <div className="inner one"></div>
            <div className="inner two"></div>
            <div className="inner three"></div>
        </div>
        <div className='text-white text-xl md:text-2xl font-serif'>
            <span>H</span>
            <span className='text-blue'> . </span>
            <span>H</span>
        </div>
    </div>
  )
}

export default Loading;


// "desc": "I am a passionate web developer, went on from html, css, and JavaScript to advanced technologies 
// like React, Redux, Node.js, and MongoDB. Currently, I'm a backend developer at Ethical Digit, creating APIs for 
// mobile and web apps. Additionally, I'm helping Thuma NGO, build a website to empower women in Myanmar. Also, studying 
// Computer Science at UOPeople to make a tech-driven impact. One of my proudest accomplishments was working with the country's 
// biggest banks to launch a safe and reliable payment gateway. This enabled clients to offer secure and easy payment options on 
// their websites and mobile apps."
