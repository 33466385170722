import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';

import { useMainContext } from '../context/mainContext';
// icons
import { BiLogoFacebook } from 'react-icons/bi';
import { BsTwitter } from 'react-icons/bs';
import  { AiOutlineInstagram } from 'react-icons/ai';
import { TfiLinkedin } from 'react-icons/tfi';

const SocialMediaIcons = () => {
    const { state: { darkTheme, language, currentPage, t }, handleDarkTheme, handleLanguageChange, handlePageChange }  = useMainContext();

    // useInView hook to detect if the icons are in view
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.2, // The percentage of the icon visible in the viewport to trigger the animation
    });

    // useAnimation hook to control the animation
    const controls = useAnimation();

    // Start the animation when the icons are in view
    useEffect(() => {
        if (inView) {
            controls.start({ 
                x: 0,
                opacity: 1,
                rotate: 360,
                transition: {
                    type: 'spring',
                    bounce: 0.4
                }
            });
        }
    }, [controls, inView]);


    return (
        <div
        className="flex items-center justify-center gap-4"
        ref={ref}
        >
        <motion.a
            className="drop-shadow-customlg"
            href="https://www.facebook.com/profile.php?id=100072278032500&mibextid=ZbWKwL"
            target="_blank"
            aria-label={t('myFb.title')}
            initial={{ x: '85px', opacity: 0 }}
            animate={controls} // Use the animation controls
            transition={{ type: 'spring', duration: 1 }}
        >
            <BiLogoFacebook className='transition-all cursor-pointer hover:bg-blue-700 hover:text-white hover:drop-shadow-lg hover:border-white w-10 h-10 rounded-full border-2 border-white text-blue-500 px-2' />
        </motion.a>
        <motion.a
            className="drop-shadow-customlg"
            href="https://instagram.com/hein_htet.arjun?utm_source=qr&igshid=NGExMmI2YTkyZg%3D%3D"
            target="_blank"
            aria-label={t('myInsta.title')}
            initial={{ x: '29px', opacity: 0 }}
            transition={{type: 'spring', duration: 1 }}
            animate={controls}
        >
            <AiOutlineInstagram className='transition-all cursor-pointer hover:bg-red-500 hover:text-white hover:drop-shadow-lg hover:border-white w-10 h-10 rounded-full border-2 border-white text-red-400 px-2' />
        </motion.a>
        <motion.a
            className="drop-shadow-customlg"
            href="https://twitter.com/hein_htet_arjun?s=09"
            target="_blank"
            aria-label={t('myTwitter.title')}
            initial={{ x: '-27px', opacity: 0 }}
            transition={{type: 'spring', duration: 1 }}
            animate={controls}
        >
            <BsTwitter className='transition-all cursor-pointer hover:bg-blue-500 hover:text-white hover:drop-shadow-lg hover:border-white w-10 h-10 rounded-full border-2 border-white text-blue-500 px-2' />
        </motion.a>
        <motion.a
            className="drop-shadow-customlg"
            href="https://linkedin.com/in/hein-82-htet"
            target="_blank"
            aria-label={t('mylinkedIn.title')}
            initial={{ x: '-85px', opacity: 0 }}
            transition={{type: 'spring', duration: 1 }}
            animate={controls}
        >
            <TfiLinkedin className='transition-all cursor-pointer hover:bg-blue-800 hover:text-white hover:drop-shadow-lg hover:border-white w-10 h-10 rounded-full border-2 border-white text-blue-500 px-2' />
        </motion.a>
        </div>
    );
};

export default SocialMediaIcons;
