import React from "react";

const Email = () => {
	return (
		<div className=" selection:bg-primary selection:dark:bg-thirdary selection:text-black max-[1260px]:hidden fixed bottom-24 -right-48 text-[12px] tracking-[.3rem] font-serif leading-none rotate-90 text-black dark:text-white flex items-center gap-9 z-[80]">
			{/* <a href='mailto:arjunyadav.hash@gmail.com' className='hover:-translate-x-1 transition-all'>arjunyadav.hash@gmail.com</a> */}
			<span className=" w-52 h-[2px] bg-gray-800 dark:bg-white block"></span>
		</div>
	);
};

export default Email;
