import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslation from './locales/en.json'; // English translation file
import frenchTranslation from './locales/fr.json'; // French translation file
import italianTranslation from './locales/it.json'; // Italian translation file
import hindiTranslation from './locales/hi.json'; // Hindi translation file

// import 'flowbite';
import './global.css';
import './fonts/font.css';
import App from './App';

import { MainContextProvider } from './context/mainContext';

let languageStrObject = localStorage.getItem('language');
let locales;
if(languageStrObject) {
  locales = JSON.parse(localStorage.getItem('language')).value;
} else {
  locales = 'en'
}

i18n
  .use(initReactI18next)
  .init({
    lng: locales, // Set the default language
    resources: {
      en: {
        translation: englishTranslation // English translation resource
      },
      fr: {
        translation: frenchTranslation // French translation resource
      },
      it: {
        translation: italianTranslation // Italian translation resource
      },
      hi: {
        translation: hindiTranslation // Hindi translation resource
      }
    },
    fallbackLng: 'en', // Fallback language in case the user's preferred language is not available
    interpolation: {
      escapeValue: false // React already escapes the values by default, so no need for extra escaping
    }
  });







const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18n}>
    <MainContextProvider>
      <Router>
        <App />
      </Router>
    </MainContextProvider>
  </I18nextProvider>
);

