import React, { useEffect } from "react";
import { useMainContext } from "../context/mainContext";
import { useInView } from "react-intersection-observer";
import {
	BiSolidQuoteAltLeft,
	BiSolidQuoteAltRight,
	BiSolidQuoteLeft,
	BiSolidQuoteRight,
} from "react-icons/bi";
import htmlImg from "../img/html.png";
// import PortfolioBackground from './homeBg';

const SecondPage = () => {
	const {
		state: { darkTheme, language, t },
		handlePageChange,
		handleToTopShow,
	} = useMainContext();

	// useInView hook to detect if the icons are in view
	const { ref, inView } = useInView({
		triggerOnce: false, // Only trigger the animation once
		threshold: 0.2, // The percentage of the icon visible in the viewport to trigger the animation
	});

	useEffect(() => {
		if (inView) {
			handlePageChange("about");
			handleToTopShow(true);
		}
	}, [inView]);

	let desc1 = t("about.desc1")
		.split(" ")
		.map((word, index) => {
			if (word.includes("1")) {
				return (
					<span key={index}>
						<span className="text-base font-mono dark:text-thirdary group overflow-hidden relative border-b-[1px] border-primary shadow-sm">
							{word.slice(0, word.length - 1)}
						</span>
						{word !== "MongoDB1" && <span>, </span>}
					</span>
				);
			} else {
				return " " + word + " ";
			}
		});

	let desc2 = t("about.desc2")
		.split(" ")
		.map((word, index) => {
			if (word == "Connected-Business-Solutions") {
				return (
					<span key={index}>
						<a
							href="https://www.connected.com.mm"
							target="_blank"
							className="text-blue-600 pb-0  dark:text-blue-400 capitalize text-lg"
						>
							{word}
						</a>
						,{" "}
					</span>
				);
			} else if (
				word.includes("-") &&
				word !== "Connected-Business-Solutions" &&
				word.includes("Thuma")
			) {
				return (
					<span key={index}>
						<a
							href="https://www.facebook.com/thuma4future/"
							target="_blank"
							className="text-blue-600 pb-0  dark:text-blue-400 capitalize text-lg"
						>
							{word.split("-").join(" ")}
						</a>
						,{" "}
					</span>
				);
			} else if (word == "UOPeople") {
				return (
					<span key={index}>
						<a
							href="https://www.uopeople.edu/"
							target="_blank"
							className="text-blue-600 pb-0  dark:text-blue-400 capitalize text-lg"
						>
							{word}
						</a>{" "}
					</span>
				);
			} else {
				return " " + word + " ";
			}
		});

	return (
		<div
			id="about"
			ref={ref}
			className="min-h-screen selection:bg-primary selection:dark:bg-thirdary selection:dark:text-black dark:bg-gray-900 bg-gray-100  text-black dark:text-gray-300 grid grid-cols-1 min-[1260px]:grid-cols-7 md:grid-cols-6 md:gap-4 md:pb-0 pb-12"
		>
			<div className="col-span-3 leading-7 flex flex-col justify-center items-start gap-7 md:ps-10 font-serif md:pt-0 pt-12 pb-6 md:pb-0">
				<h2 className="selection:text-black text-primary drop-shadow-customlg border-s-[2px] border-e-thirdary border-s-secondary md:ms-0 ms-5 ps-5 text-3xl md:text-4xl capitalize mb-5">
					{t("about.title")}{" "}
					<span className="text-primary dark:text-thirdary">?</span>
				</h2>
				<p
					className={`${
						language.value == "hi" ? "text-lg" : "text-xl"
					} font-para md:px-0 px-5`}
				>
					{desc1}
					{language.value !== "hi" && "."}
				</p>
				<p
					className={`${
						language.value == "hi" ? "text-lg" : "text-xl"
					} font-para md:px-0 px-5`}
				>
					{desc2}
				</p>
				<p
					className={`${
						language.value == "hi" ? "text-lg" : "text-xl"
					} font-para md:px-0 px-5`}
				>
					<span className="border-s-2 border-secondary px-1 bg-gray-200 dark:bg-gray-800">
						{t("about.desc3")}
					</span>
				</p>
			</div>
			<div className="col-span-3 flex justify-end items-center md:mt-0 mt-8">
				<div className="text-center border-x-2 dark:border-gray-500 md:px-2 px-5 font-para">
					<BiSolidQuoteLeft className="inline mb-2 text-lg md:text-xl me-2 " />
					<span>{t("about.quote")}</span>
					<BiSolidQuoteRight className="inline mb-2 text-lg md:text-xl ms-2" />
					<div className="text-right mt-5">
						<span>- Bingster</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SecondPage;

{
	/* <p className=''>
          <span className='text-2xl'>I</span>
          'm a passionate {' '}
          <span className=' capitalize p-1 text-secondary'>web developer</span> {' '}
          who transitioned from {' '}
          <span className='text-base font-mono dark:text-thirdary group overflow-hidden relative'>
            html
            <span className='w-full h-[2px] absolute top-full left-0 bg-gray-300 dark:bg-gray-600'>
              <span className='relative block w-full h-[2px] overflow-x-hidden'>
                <span className='absolute block top-0 left-0 bg-secondary dark:bg-secondary -translate-x-full w-full h-[2px] group-hover:translate-x-0 transition-all z-30'></span>
              </span>
            </span>
          </span>, {' '}
          <span className='text-base font-mono dark:text-thirdary group overflow-hidden relative'>
            css
            <span className='w-full h-[2px] absolute top-full left-0 bg-gray-300 dark:bg-gray-600'>
              <span className='relative block w-full h-[2px] overflow-x-hidden'>
                <span className='absolute block top-0 left-0 bg-secondary dark:bg-secondary -translate-x-full w-full h-[2px] group-hover:translate-x-0 transition-all z-30'></span>
              </span>
            </span>
          </span>, {' '}
          and {' '}
          <span className='text-base font-mono dark:text-thirdary group overflow-hidden relative'>
            JavaScript
            <span className='w-full h-[2px] absolute top-full left-0 bg-gray-300 dark:bg-gray-600'>
              <span className='relative block w-full h-[2px] overflow-x-hidden'>
                <span className='absolute block top-0 left-0 bg-secondary dark:bg-secondary -translate-x-full w-full h-[2px] group-hover:translate-x-0 transition-all z-30'></span>
              </span>
            </span>
          </span> {' '}
          to advanced technologies 
          like {' '}
          <span className='text-base font-mono dark:text-thirdary group overflow-hidden relative'>
            React
            <span className='w-full h-[2px] absolute top-full left-0 bg-gray-300 dark:bg-gray-600'>
              <span className='relative block w-full h-[2px] overflow-x-hidden'>
                <span className='absolute block top-0 left-0 bg-secondary dark:bg-secondary -translate-x-full w-full h-[2px] group-hover:translate-x-0 transition-all z-30'></span>
              </span>
            </span>
          </span>, {' '}
          <span className='text-base font-mono dark:text-thirdary group overflow-hidden relative'>
            Redux
            <span className='w-full h-[2px] absolute top-full left-0 bg-gray-300 dark:bg-gray-600'>
              <span className='relative block w-full h-[2px] overflow-x-hidden'>
                <span className='absolute block top-0 left-0 bg-secondary dark:bg-secondary -translate-x-full w-full h-[2px] group-hover:translate-x-0 transition-all z-30'></span>
              </span>
            </span>
          </span>, {' '}
          <span className='text-base font-mono dark:text-thirdary group overflow-hidden relative'>
            Node.js
            <span className='w-full h-[2px] absolute top-full left-0 bg-gray-300 dark:bg-gray-600'>
              <span className='relative block w-full h-[2px] overflow-x-hidden'>
                <span className='absolute block top-0 left-0 bg-secondary dark:bg-secondary -translate-x-full w-full h-[2px] group-hover:translate-x-0 transition-all z-30'></span>
              </span>
            </span>
          </span>, {' '}
          and {' '}
          <span className='text-base font-mono dark:text-thirdary group overflow-hidden relative'>
            MongoDB
            <span className='w-full h-[2px] absolute top-full left-0 bg-gray-300 dark:bg-gray-600'>
              <span className='relative block w-full h-[2px] overflow-x-hidden'>
                <span className='absolute block top-0 left-0 bg-secondary dark:bg-secondary -translate-x-full w-full h-[2px] group-hover:translate-x-0 transition-all z-30'></span>
              </span>
            </span>
          </span>.
        </p>
        <p>
          As a {" "} 
          <span className=' capitalize  p-1 text-secondary'>Remote Backend Developer</span> {" "}
          at {" "}
          <a href='https://www.connected.com.mm' target='_blank' className='border-b-2 border-gray-100 shadow-lg'>Ethical Digit</a>, {" "}
          I specialize in crafting super-efficient {" "}
          <span className='border-b-2 border-gray-100 pb-[2px] shadow-lg'>APIs for mobile apps and websites</span>. {" "}
          When I'm not busy coding, I love volunteering at Thuma NGO, helping them with website development to empower women in Myanmar. 
          Currently, I'm also pursuing my first-year in Computer Science at <a href='https://www.connected.com.mm' target='_blank' className='border-b-2 border-gray-50 shadow-lg'>UOPeople</a> , always striving to deliver top-notch solutions and 
          make a positive impact through technology.
        </p> */
}
