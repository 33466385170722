import React, { useState } from 'react';
import { FiMaximize } from 'react-icons/fi';
import { useMainContext } from '../context/mainContext';

const Lightbox = ({ imageUrls, view }) => {
  const { state: { t } }  = useMainContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleOpen = (index) => {
    // setSelectedImageIndex(index);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handlePrev = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className='absolute top-0 right-0'>
        <div className={`relative ${isOpen ? 'z-50' : 'z-30'}`}>
            <FiMaximize onClick={() => handleOpen()} className='p-1 text-4xl cursor-pointer bg-black bg-opacity-60 text-white block' title={t('projects.btns.ss')} />
            {/* Lightbox */}
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                <div className=" w-5/6 mx-auto p-4">
                    <img src={imageUrls[selectedImageIndex]} alt="Lightbox" className={`${ view == 'mobile' && 'max-w-[290px] h-auto mx-auto' } w-full`} />
                    <button
                    className="absolute top-0 right-0 p-4 text-white text-4xl"
                    onClick={handleClose}
                    >
                    &times;
                    </button>
                    {imageUrls.length > 1 && (
                    <>
                        <button
                        className="absolute top-1/2 left-0 transform -translate-y-1/2 mt-4 p-4 text-white text-4xl bg-gray-800 bg-opacity-30 py-10"
                        onClick={handlePrev}
                        >
                        &lsaquo;
                        </button>
                        <button
                        className="absolute top-1/2 right-0 transform -translate-y-1/2 mt-4 p-4 text-white text-4xl bg-gray-800 bg-opacity-30 py-10"
                        onClick={handleNext}
                        >
                        &rsaquo;
                        </button>
                        <Pagination
                          totalSlides={imageUrls.length}
                          currentIndex={selectedImageIndex}
                        />
                    </>
                    )}
                </div>
                </div>
            )}
        </div>
    </div>
  );
};


const Pagination = ({ totalSlides, currentIndex }) => {
  const indicators = Array.from({ length: totalSlides }, (_, index) => (
    <div
      key={index}
      className={`w-2 h-2 rounded-full mx-1 ${
        index === currentIndex ? 'bg-white' : 'bg-gray-500'
      }`}
    ></div>
  ));

  return <div className="flex items-center justify-center mt-4">{indicators}</div>;
};

export default Lightbox;
