import React, { createContext, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";

const mainContext = createContext();


export const MainContextProvider = ({ children }) => {
    const [ darkTheme, setDarkTheme ] = useState(() => localStorage.getItem('darkTheme') == 'true' ? true : false);
    const [ submitting, setSubmitting ] = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ ratingMessage, setRatingMessage ] = useState(null);
    const [ toTopShow, setToTopShow ] = useState(false);
    const [ ratings, setRatings ] = useState([]);
    const [ language, setLanguage ] = useState(() => localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : { value: 'en', text: "English (UK)" });
    const [ currentPage, setCurrentPage ] = useState("home");
    const [ languageDropDown, setLanguageDropDown ] = useState({ drop: false });
    const [ menuDropDown, setMenuDropDown ] = useState({ drop: false });

    const { t, i18n } = useTranslation();

    const handleDarkTheme = () => {
        setDarkTheme(!darkTheme);
        localStorage.setItem('darkTheme', !darkTheme);
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);

        setTimeout(() => {
            setMenuDropDown({ drop: false });
        }, 100)
    };
    const handleLanguageDropDown = (languageDropDown) => {
        setLanguageDropDown(languageDropDown);
    };
    const handleMenuDropDown = (menuDropDown) => {
        setMenuDropDown(menuDropDown);
    };
    const handleLanguageChange = (language) => {
        setLanguage(language);
        i18n.changeLanguage(language.value).catch(err => console.error('Failed to change language:', err));
        localStorage.setItem('language', JSON.stringify(language));
    };
    const getAllRating = async () => {
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://portfolio-v1-server.vercel.app/projects`,
                headers: { 
                    'Content-Type': 'application/json'
                },
            };
    
            const response = await axios.request(config);
            return response.data.projects;
        } catch (error) {
            console.log(error);
        }
    };
    const handleRating = (projectNo, rating) => {
        let data = JSON.stringify({
            "rating": rating
        });
        
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://portfolio-v1-server.vercel.app/projects/rating/${projectNo}`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios.request(config)
        .then((response) => {
            setRatingMessage('Thanks for rating');
            getAllRating();

            setTimeout(() => {
                setRatingMessage(null);
            }, 3000);
        })
        .catch((error) => {
            setRatingMessage('Thanks, an error occur, try again later');
        });
          
    };
    const handleContactMessage = async (userData) => {
        let data = JSON.stringify(userData);
        
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://portfolio-v1-server.vercel.app/send_email`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios.request(config)
        .then((response) => {
            setRatingMessage("Thanks. I'll contact you soon!");
            setSubmitting(false);
            setSending(false);

            setTimeout(() => {
                setRatingMessage(null);
            }, 3000);
        })
        .catch((error) => {
            setRatingMessage('Thanks, an error occur, try again later');
        });
    };
    const handleToTopShow = (show) => {
        setToTopShow(show);
    };
    const handleLoading = (load) => {
        if(load == 'send') {
            setSending(true);
        } else {
            setSubmitting(true);
        }
    }
    
    return (
        <mainContext.Provider
        value={{ 
            state: {
                darkTheme,
                language,
                currentPage,
                languageDropDown,
                menuDropDown,
                ratings,
                ratingMessage,
                toTopShow,
                submitting,
                sending,
                t
            },
            handleDarkTheme,
            handleLanguageChange,
            handlePageChange,
            handleLanguageDropDown,
            handleMenuDropDown,
            handleRating,
            handleContactMessage,
            handleToTopShow,
            setRatingMessage,
            setRatings,
            handleLoading,
            getAllRating,
        }}
        >
        {children}
        </mainContext.Provider>
    );
};

export const useMainContext = () => useContext(mainContext);
