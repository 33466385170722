import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import { useMainContext } from '../context/mainContext';
import Rating from './rating';

/* ----------------------  hulu screen shots start -------------------------- */
// for laptop
import outsideHuluF from '../img/hulu/o-first.png';
import outsideHuluS from '../img/hulu/o-second.png';
import outsideHuluT from '../img/hulu/o-third.png';
import outsideHuluFs from '../img/hulu/o-fourth.png';

import insideHuluF from '../img/hulu/i-first.png';
import insideHuluS from '../img/hulu/i-second.png';
import insideHuluT from '../img/hulu/i-third.png';
import insideHuluFs from '../img/hulu/i-fourth.png';
import insideHuluFt from '../img/hulu/i-fifth.png';

// for mobile
import outsideMHuluF from '../img/hulu/o-m-first.jpg';
import outsideMHuluS from '../img/hulu/o-m-second.jpg';
import outsideMHuluT from '../img/hulu/o-m-third.jpg';


import insideMHuluF from '../img/hulu/i-m-first.jpg';
import insideMHuluS from '../img/hulu/i-m-second.jpg';
import insideMHuluT from '../img/hulu/i-m-third.jpg';
/* ----------------------  hulu screen shots end  -------------------------- */


/* ----------------------  travel advisor screen shots start -------------------------- */
// for laptop
import travelF2 from '../img/advisor/first2.png';
import travelF from '../img/advisor/first.png';
import travelS from '../img/advisor/second.png';
import travelT from '../img/advisor/third.png';

// for mobile
import mTravelF from '../img/advisor/m-first.jpg';
import mTravelS from '../img/advisor/m-second.jpg';
import mTravelT from '../img/advisor/m-third.jpg';
/* ----------------------  travel advisor screen shots end -------------------------- */


/* ----------------------  Quote screen shots start -------------------------- */
// for laptop
import quoteF2 from '../img/quote/first2.png';
import quoteF from '../img/quote/first.png';
import quoteS from '../img/quote/second.png';
import quoteT from '../img/quote/third.png';


// for mobile
import mQuoteF from '../img/quote/m-first.jpg';
import mQuoteS from '../img/quote/m-second.jpg';
import mQuoteT from '../img/quote/m-third.jpg';
/* ----------------------  Quote screen shots end -------------------------- */


/* ----------------------  markdown screen shots start -------------------------- */
// for laptop
import markdownF2 from '../img/markdown/first2.png';
import markdownF from '../img/markdown/first.png';
import markdownS from '../img/markdown/second.png';
import markdownT from '../img/markdown/third.png';


// for mobile
import mMarkdownF from '../img/markdown/m-first.jpg';
import mMarkdownS from '../img/markdown/m-second.jpg';
import mMarkdownT from '../img/markdown/m-third.jpg';
/* ----------------------  markdown screen shots end -------------------------- */


/* ----------------------  calculator shots start -------------------------- */
// for laptop
import calculatorF2 from '../img/calculator/first2.png';
import calculatorF from '../img/calculator/first.png';
import calculatorS from '../img/calculator/second.png';
import calculatorT from '../img/calculator/third.png';


// for mobile
import mCalculatorF from '../img/calculator/m-first.jpg';
import mCalculatorS from '../img/calculator/m-second.jpg';
import mCalculatorT from '../img/calculator/m-third.jpg';
/* ----------------------  calculator shots end -------------------------- */

/* ---------------------- game shots start -------------------------- */
// for laptop
import gameF2 from '../img/game/first2.png';
import gameF from '../img/game/first.png';
import gameS from '../img/game/second.png';
import gameT from '../img/game/third.png';
import gameFs from '../img/game/fourth.png';


// for mobile
import mGameF from '../img/game/m-first.jpg';
import mGameS from '../img/game/m-second.jpg';
import mGameT from '../img/game/m-third.jpg';
import mGameFs from '../img/game/m-fourth.jpg';
import mGameFt from '../img/game/m-fifth.jpg';
/* ---------------------- game shots end -------------------------- */

/* ---------------------- ecormace screen shots start -------------------------- */
// for laptop
import ecormaceF2 from '../img/ecormace/first2.png';
import ecormaceF from '../img/ecormace/first.png';
import ecormaceS from '../img/ecormace/second.png';
import ecormaceT from '../img/ecormace/third.png';
import ecormaceFs from '../img/ecormace/fourth.png';


// for mobile
import mEcormaceF from '../img/ecormace/m-first.jpg';
import mEcormaceS from '../img/ecormace/m-second.jpg';
import mEcormaceT from '../img/ecormace/m-third.jpg';
import mEcormaceFs from '../img/ecormace/m-fourth.jpg';
import mEcormaceFt from '../img/ecormace/m-fifth.jpg';
/* ---------------------- ecormace screen shots end -------------------------- */

/* ----------------------  library screen shots start -------------------------- */
// for laptop
import libraryF2 from '../img/library/first2.png';
import libraryF from '../img/library/first.png';
import libraryS from '../img/library/second.png';
import libraryT from '../img/library/third.png';
import libraryFs from '../img/library/fourth.png';
import libraryft from '../img/library/fifth.png';
import librarySx from '../img/library/sixth.png';

// for mobile
import mLibraryF from '../img/library/m-first.jpg';
import mLibraryS from '../img/library/m-second.jpg';
import mLibraryT from '../img/library/m-third.jpg';
import mLibraryFs from '../img/library/m-fourth.jpg';
import mLibraryFt from '../img/library/m-fifth.jpg';
/* ----------------------  library screen shots end -------------------------- */

/* ----------------------  portfolio screen shots start -------------------------- */
// for laptop
import portfolioF2 from '../img/portfolio/first2.png';
import portfolioF from '../img/portfolio/first.png';
import portfolioS from '../img/portfolio/second.png';
import portfolioT from '../img/portfolio/third.png';
import portfolioFs from '../img/portfolio/fourth.png';
import portfolioFt from '../img/portfolio/fifth.png';
import portfolioSx from '../img/portfolio/sixth.png';
import portfolioSv from '../img/portfolio/seventh.png';
import portfolioE from '../img/portfolio/eight.png';

// for mobile
import mPortfolioF from '../img/portfolio/m-first.jpg';
import mPortfolioS from '../img/portfolio/m-second.jpg';
import mPortfolioT from '../img/portfolio/m-third.jpg';
import mPortfolioFs from '../img/portfolio/m-fourth.jpg';
import mPortfolioFt from '../img/portfolio/m-fifth.jpg';
import mPortfolioSx from '../img/portfolio/m-sixth.jpg';
import mPortfolioSv from '../img/portfolio/m-seventh.jpg';
import mPortfolioE from '../img/portfolio/m-eight.jpg';
import mPortfolioN from '../img/portfolio/m-ninth.jpg';
import mPortfolioTn from '../img/portfolio/m-tenth.jpg';
/* ----------------------  portfolio screen shots end -------------------------- */



// icons
import { FiArrowUpRight } from 'react-icons/fi';

import Lightbox from './lightbox';



const FourthPage = () => {
    const { state: { t, ratings }, handlePageChange, setRatings, getAllRating, handleToTopShow }  = useMainContext();
    const [ moreProject, setMoreProject ] = useState({
        first: {
            open: false,
        },
        second: {
            open: false,
        }
    });
    const showMoreButtonRef1 = useRef(null);
    const showMoreButtonRef2 = useRef(null);
    const [ huluImageUrls, setHuluImageUrls ] = useState([]);
    const [ advisorImageUrls, setAdvisorImageUrls ] = useState([]);
    const [ libraryImageUrls, setLibraryImageUrls ] = useState([]);
    const [ quoteImageUrls, setQuoteImageUrls ] = useState([]);
    const [ portfolioImageUrls, setPortfolioImageUrls ] = useState([]);
    const [ ecormaceImageUrls, setEcormaceImageUrls ] = useState([]);
    const [ markdownImageUrls, setMarkdownImageUrls ] = useState([]);
    const [ gameImageUrls, setGameImageUrls ] = useState([]);
    const [ calculatorImageUrls, setCalculatorImageUrls ] = useState([]);
    const [ view, setView ] = useState('laptop');
    // useInView hook to detect if the icons are in view
    const { ref, inView } = useInView({
        triggerOnce: false, // Only trigger the animation once
        threshold: 0.2, // The percentage of the icon visible in the viewport to trigger the animation
    });
 
    useEffect(() => {
        if(window.innerWidth <= 650) {
            setView('mobile')
            setHuluImageUrls([
                outsideMHuluF,
                outsideMHuluS,
                outsideMHuluT,
                insideMHuluF,
                insideMHuluS,
                insideMHuluT,
            ]);
            setPortfolioImageUrls([
                mPortfolioF, 
                mPortfolioS, 
                mPortfolioT, 
                mPortfolioFs,
                mPortfolioFt,
                mPortfolioSx,
                mPortfolioSv,
                mPortfolioE,
                mPortfolioN,
                mPortfolioTn,
            ]);
            setLibraryImageUrls([
                mLibraryF, 
                mLibraryS, 
                mLibraryT, 
                mLibraryFs,
                mLibraryFt,
            ]);
            setEcormaceImageUrls([
                mEcormaceF, 
                mEcormaceS, 
                mEcormaceT, 
                mEcormaceFs,
                mEcormaceFt,
            ]);
            setGameImageUrls([
                mGameF, 
                mGameS, 
                mGameT, 
                mGameFs,
                mGameFt,
            ]);
            setCalculatorImageUrls([
                mCalculatorF,
                mCalculatorS,
                mCalculatorT,
            ]);
            setMarkdownImageUrls([
                mMarkdownF,
                mMarkdownS,
                mMarkdownT,
            ]);
            setQuoteImageUrls([
                mQuoteF,
                mQuoteS,
                mQuoteT,
            ]);
            setAdvisorImageUrls([
                mTravelF,
                mTravelS,
                mTravelT,
            ]);
        } else {
            setHuluImageUrls( [
                outsideHuluF,
                outsideHuluS,
                outsideHuluT,
                outsideHuluFs,
                insideHuluF,
                insideHuluS,
                insideHuluT,
                insideHuluFs,
                insideHuluFt,
            ]);
            setPortfolioImageUrls([
                portfolioF, 
                portfolioS, 
                portfolioT, 
                portfolioFs,
                portfolioFt,
                portfolioSx,
                portfolioSv,
                portfolioE, 
            ]);
            setLibraryImageUrls([
                libraryF, 
                libraryS, 
                libraryT, 
                libraryFs,
                libraryft,
                librarySx,
            ]);
            setGameImageUrls([
                gameF, 
                gameS, 
                gameT, 
                gameFs,
            ]);
            setEcormaceImageUrls([
                ecormaceF, 
                ecormaceS, 
                ecormaceT, 
                ecormaceFs,
            ]);
            setCalculatorImageUrls([
                calculatorF,
                calculatorS,
                calculatorT,
            ]);
            setMarkdownImageUrls([
                markdownF,
                markdownS,
                markdownT,
            ]);
            setQuoteImageUrls([
                quoteF,
                quoteS,
                quoteT,
            ]);
            setAdvisorImageUrls([
                travelF,
                travelS,
                travelT,
            ]);
        }
    }, []);



    const handleShowMore = () => {
        if(moreProject.first.open) {
            setMoreProject({
                first: {
                    open: true,
                },
                second: {
                    open: true,
                }
            });

        } else {
            setMoreProject({
                first: {
                    open: true,
                },
                second: {
                    open: false,
                }
            })
        }
    };

    const handleShowLess = () => {
        if(moreProject.second.open) {
            setMoreProject({
                first: {
                    open: true,
                },
                second: {
                    open: false,
                }
            });
        } else {
            setMoreProject({
                first: {
                    open: false,
                },
                second: {
                    open: false,
                }
            });
        }
    }
    const handleShowLessAndScroll = () => {
        handleShowLess();
        if (showMoreButtonRef1.current) {
            showMoreButtonRef1.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    useEffect(() => {
        if(inView) {
          handlePageChange("projects");
          handleToTopShow(true);
        }
    }, [inView]);


    
    useEffect(() => {
        const fetchRatings = async () => {
            try {
                const ratingArr = await getAllRating();
                setRatings(ratingArr);
            } catch (error) {
                // Handle errors
            }
        };
    
        fetchRatings();
    }, []);




    return (
        <div ref={ref} id="projects" className='min-h-screen dark:bg-gray-900 bg-gray-100 px-5 md:px-10 py-16 selection:bg-primary dark:selection:bg-thirdary dark:selection:text-black'>
            <h2 className='selection:text-black text-primary drop-shadow-customlg border-s-[2px] border-e-thirdary border-s-secondary ps-5 text-3xl md:text-4xl capitalize'>{t('projects.title')}</h2> 
            <div className='flex flex-col gap-10 md:gap-24 mt-10 md:mt-20 relative'>
                <div  className='hidden md:block absolute top-0 left-1/2 transform -translate-x-1/2 w-[3px] h-full bg-primary dark:bg-gray-600'></div>
                <div className='grid grid-cols-1 md:grid-cols-10 gap-2'>
                    <div className='col-span-1'></div>
                    <div className='col-span-4 group mt-3 md:mt-0'>
                        <div className='relative group overflow-hidden'>
                            <img alt={t('projects.project1.alt')} src={outsideHuluF} className='group-hover:scale-105 duration-300 transition-all' />
                            <Lightbox imageUrls={huluImageUrls} view={view} />
                            <span className='text-white absolute border-[1px] border-white backdrop-blur-lg text-sm lg:text-base px-2 lg:px-4 py-1 lg:py-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-100 group-hover:opacity-0 transition-all duration-300'>{view == 'mobile' ? 'Click' : "Hover"} me</span>
                            <div className='absolute w-11/12 h-5/6 top-0 group-hover:top-1/2 left-1/2 transform -translate-y-full group-hover:-translate-y-1/2 -translate-x-1/2 bg-white bg-opacity-50 flex  justify-center items-center gap-4 transition-all duration-300 z-10'>
                                <a href="https://github.com/JamesCartar/hulu-frontend" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Source Code</a>
                                <a href="https://hulu-frontend.vercel.app/home" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Visit Website</a>
                            </div>
                        </div>
                        <div className='dark:text-gray-200 flex-wrap justify-around gap-y-2 gap-2 items-center hidden md:flex lg:hidden py-2' aria-label='used technologies'>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>React</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Tailwind</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Axios</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Nodejs</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Express</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Mongodb</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Mongoose</span>
                        </div >
                    </div>
                    <div className='col-span-4 flex flex-col justify-center items-start p-0 md:px-5 mt-5 md:mt-0'>
                        <a href="https://hulu-frontend.vercel.app/home" target='_blank' className='flex justify-start items-center gap-1 text-blue-600 dark:text-thirdary group'>
                            <h3 className='selection:text-black text-xl font-mono font-bold'>{t('projects.project1.title')}</h3>
                            <FiArrowUpRight className='group-hover:translate-x-[2px] group-hover:-translate-y-[2px] transition-all' />
                        </a>
                        <p className='mt-3 text-[15px] dark:text-gray-100'>{t('projects.project1.desc')}</p>
                        <Rating projectNo={1} projectRating={ratings[0]?.rate} />
                        <div className='flex-wrap gap-2 items-center mt-4 justify-between flex md:hidden lg:flex' aria-label='used technologies'>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>React</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Tailwind</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Axios</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Nodejs</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Express</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Mongodb</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Mongoose</span>
                        </div >
                    </div>
                    <div className='col-span-1'></div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-10 gap-2'>
                    <div className='col-span-1 order-1'></div>
                    <div className='col-span-4 group mt-3 md:mt-0 order-2 md:order-3'>
                        <div className='relative group overflow-hidden'>
                            <img alt={t('projects.project2.alt')} src={travelF2} className='group-hover:scale-105 duration-300 transition-all' />
                            <Lightbox imageUrls={advisorImageUrls} view={view} />
                            <span className='text-white absolute border-[1px] border-white backdrop-blur-lg text-sm lg:text-base px-2 lg:px-4 py-1 lg:py-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-100 group-hover:opacity-0 transition-all duration-300'>{view == 'mobile' ? 'Click' : "Hover"} me</span>
                            <div className='absolute w-11/12 h-5/6 top-0 group-hover:top-1/2 left-1/2 transform -translate-y-full group-hover:-translate-y-1/2 -translate-x-1/2 bg-white bg-opacity-50 flex  justify-center items-center gap-4 transition-all duration-300 z-10'>
                                <a href="https://github.com/JamesCartar/travel-advisor-react.git" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Source Code</a>
                                <a href="https://tra-vel-advisor.netlify.app" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Visit Website</a>
                            </div>
                        </div>
                        <div className='dark:text-gray-200 flex-wrap justify-around gap-y-2 gap-2 items-between hidden md:flex lg:hidden py-2' aria-label='used technologies'>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none text-center flex-1 py-[2px] px-4 md:px-2 whitespace-nowrap'>React</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none text-center flex-1 py-[2px] px-4 md:px-2 whitespace-nowrap'>Context API</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none text-center flex-1 py-[2px] px-4 md:px-2 whitespace-nowrap'>Material UI</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none text-center flex-1 py-[2px] px-4 md:px-2 whitespace-nowrap'>Axios</span>
                        </div >
                    </div>
                    <div className='col-span-4 flex flex-col justify-center items-start p-0 md:px-5 mt-5 md:mt-0 order-3 md:order-2'>
                        <a href="https://tra-vel-advisor.netlify.app" target='_blank' className='flex justify-start items-center gap-1 text-blue-600 dark:text-thirdary group'>
                            <h3 className='selection:text-black text-xl font-mono font-bold'>{t('projects.project2.title')}</h3>
                            <FiArrowUpRight className='group-hover:translate-x-[2px] group-hover:-translate-y-[2px] transition-all' />
                        </a>
                        <p className='mt-3 text-[15px] dark:text-gray-100'>{t('projects.project2.desc')}</p>
                        <Rating projectNo={2} projectRating={ratings[1]?.rate} />
                        <div className='flex-wrap gap-2 items-center mt-4 justify-between flex md:hidden lg:flex' aria-label='used technologies'>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] flex-1 border-gray-300 md:rounded-none text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>React</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] flex-1 border-gray-300 md:rounded-none text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Context API</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] flex-1 border-gray-300 md:rounded-none text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Material UI</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] flex-1 border-gray-300 md:rounded-none text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Axios</span>
                        </div >
                    </div>
                    <div className='col-span-1 order-4'></div>
                </div>
                <div ref={!moreProject.second.open ? showMoreButtonRef1 : showMoreButtonRef2 } className='grid grid-cols-1 md:grid-cols-10 gap-2'>
                    <div className='col-span-1'></div>
                    <div className='col-span-4 group mt-3 md:mt-0'>
                        <div className='relative group overflow-hidden'>
                            <img alt={t('projects.project3.alt')} src={libraryF2} className='group-hover:scale-105 duration-300 transition-all' />
                            <Lightbox imageUrls={libraryImageUrls} view={view} />
                            <span className='text-white absolute border-[1px] border-white backdrop-blur-lg text-sm lg:text-base px-2 lg:px-4 py-1 lg:py-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-100 group-hover:opacity-0 transition-all duration-300'>{view == 'mobile' ? 'Click' : "Hover"} me</span>
                            <div className='absolute w-11/12 h-5/6 top-0 group-hover:top-1/2 left-1/2 transform -translate-y-full group-hover:-translate-y-1/2 -translate-x-1/2 bg-white bg-opacity-50 flex  justify-center items-center gap-4 transition-all duration-300 z-10'>
                                <a href="https://github.com/JamesCartar/my_library.git" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Source Code</a>
                                <a href="https://my-library-seven.vercel.app" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Visit Website</a>
                            </div>
                        </div>
                        <div className='dark:text-gray-200 flex-wrap justify-around gap-y-2 gap-2 items-center hidden md:flex lg:hidden py-2' aria-label='used technologies'>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px] whitespace-nowrap'>Ejs Engine</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Css</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>JavaScript</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Cherio</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Axios</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Nodejs</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Mongodb</span>
                            <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Mongoose</span>
                        </div >
                    </div>
                    <div className='col-span-4 flex flex-col justify-center items-start p-0 md:px-5 mt-5 md:mt-0'>
                        <a href="https://my-library-seven.vercel.app" target='_blank' className='flex justify-start items-center gap-1 text-blue-600 dark:text-thirdary group'>
                            <h3 className='selection:text-black text-xl font-mono font-bold'>{t('projects.project3.title')}</h3>
                            <FiArrowUpRight className='group-hover:translate-x-[2px] group-hover:-translate-y-[2px] transition-all' />
                        </a>
                        <p className='mt-3 text-[15px] dark:text-gray-100'>{t('projects.project3.desc')}</p>
                        <Rating projectNo={3} projectRating={ratings[2]?.rate} />
                        <div className='flex-wrap gap-2 items-center mt-4 justify-between flex md:hidden lg:flex' aria-label='used technologies'>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Ejs Engine</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Css</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>JavaScript</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Cherio</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Axios</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Nodejs</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Mongodb</span>
                            <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Mongoose</span>
                        </div >
                    </div>
                    <div className='col-span-1'></div>
                </div>
                <div className={`flex-col justify-center items-center gap-9 md:gap-20 overflow-hidden transition-all duration-1000 ${moreProject.first.open ? 'flex h-auto' : 'hidden h-0'}`}>
                    <div className='grid grid-cols-1 md:grid-cols-10 gap-2'>
                        <div className='col-span-1 order-1'></div>
                        <div className='col-span-4 group mt-3 md:mt-0 order-2 md:order-3'>
                            <div className='relative group overflow-hidden'>
                                <img alt={t('projects.project4.alt')} src={portfolioF2} className='group-hover:scale-105 duration-300 transition-all' />
                                <Lightbox imageUrls={portfolioImageUrls} view={view} />
                                <span className='text-white absolute border-[1px] border-white backdrop-blur-lg text-sm lg:text-base px-2 lg:px-4 py-1 lg:py-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-100 group-hover:opacity-0 transition-all duration-300'>{view == 'mobile' ? 'Click' : "Hover"} me</span>
                                <div className='absolute w-11/12 h-5/6 top-0 group-hover:top-1/2 left-1/2 transform -translate-y-full group-hover:-translate-y-1/2 -translate-x-1/2 bg-white bg-opacity-50 flex  justify-center items-center gap-4 transition-all duration-300 z-10'>
                                    <a href="https://github.com/JamesCartar/bootstrap-portfolio.git" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Source Code</a>
                                    <a href="https://bootstrap-portfoliowb.netlify.app" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Visit Website</a>
                                </div>
                            </div>
                            <div className='dark:text-gray-200 flex-wrap justify-around gap-y-2 gap-2 items-center hidden md:flex lg:hidden py-2' aria-label='used technologies'>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Html</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Css</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Sass</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Bootstrap</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>JavaScript</span>
                            </div >
                        </div>
                        <div className='col-span-4 flex flex-col justify-center items-start p-0 md:px-5 mt-5 md:mt-0 order-3 md:order-2'>
                            <a href="https://bootstrap-portfoliowb.netlify.app" target='_blank' className='flex justify-start items-center gap-1 text-blue-600 dark:text-thirdary group'>
                                <h3 className='selection:text-black text-xl font-mono font-bold'>{t('projects.project4.title')}</h3>
                                <FiArrowUpRight className='group-hover:translate-x-[2px] group-hover:-translate-y-[2px] transition-all' />
                            </a>
                            <p className='mt-3 text-[15px] dark:text-gray-100'>{t('projects.project4.desc')}</p>
                            <Rating projectNo={4} projectRating={ratings[3]?.rate} />
                            <div className='flex-wrap gap-2 items-center mt-4 justify-between flex md:hidden lg:flex' aria-label='used technologies'>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Html</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Css</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Sass</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Bootstrap</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>JavaScript</span>
                            </div >
                        </div>
                        <div className='col-span-1 order-4'></div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-10 gap-2'>
                        <div className='col-span-1'></div>
                        <div className='col-span-4 group mt-3 md:mt-0'>
                            <div className='relative group overflow-hidden'>
                                <img alt={t('projects.project5.alt')} src={ecormaceF2} className='group-hover:scale-105 duration-300 transition-all' />
                                <Lightbox imageUrls={ecormaceImageUrls} view={view} />
                                <span className='text-white absolute border-[1px] border-white backdrop-blur-lg text-sm lg:text-base px-2 lg:px-4 py-1 lg:py-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-100 group-hover:opacity-0 transition-all duration-300'>{view == 'mobile' ? 'Click' : "Hover"} me</span>
                                <div className='absolute w-11/12 h-5/6 top-0 group-hover:top-1/2 left-1/2 transform -translate-y-full group-hover:-translate-y-1/2 -translate-x-1/2 bg-white bg-opacity-50 flex  justify-center items-center gap-4 transition-all duration-300 z-10'>
                                    <a href="https://github.com/JamesCartar/react-phone-store.git" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Source Code</a>
                                    <a href="https://react-phone-ecormace-app.netlify.app" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Visit Website</a>
                                </div>
                            </div>
                            <div className='dark:text-gray-200 flex-wrap justify-around gap-y-2 gap-2 items-center hidden md:flex lg:hidden py-2' aria-label='used technologies'>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px] whitespace-nowrap'>React</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px] whitespace-nowrap'>Context API</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px] whitespace-nowrap'>Styled Component</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px] whitespace-nowrap'>Bootstrap</span>
                            </div >
                        </div>
                        <div className='col-span-4 flex flex-col justify-center items-start p-0 md:px-5 mt-5 md:mt-0'>
                            <a href="https://react-phone-ecormace-app.netlify.app" target='_blank' className='flex justify-start items-center gap-1 text-blue-600 dark:text-thirdary group'>
                                <h3 className='selection:text-black text-xl font-mono font-bold'>{t('projects.project5.title')}</h3>
                                <FiArrowUpRight className='group-hover:translate-x-[2px] group-hover:-translate-y-[2px] transition-all' />
                            </a>
                            <p className='mt-3 text-[15px] dark:text-gray-100'>{t('projects.project5.desc')}</p>
                            <Rating projectNo={5} projectRating={ratings[4]?.rate} />
                            <div className='flex-wrap gap-2 items-center mt-4 justify-between flex md:hidden lg:flex' aria-label='used technologies'>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>React</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Context API</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Styled Component</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Bootstrap</span>
                            </div >
                        </div>
                        <div className='col-span-1'></div>
                    </div>
                    <div ref={moreProject.second.open ? showMoreButtonRef1 : showMoreButtonRef2} className='grid grid-cols-1 md:grid-cols-10 gap-2'>
                        <div className='col-span-1 order-1'></div>
                        <div className='col-span-4 group mt-3 md:mt-0 order-2 md:order-3'>
                            <div className='relative group overflow-hidden'>
                                <img alt={t('projects.project6.alt')} src={quoteF2} className='group-hover:scale-105 duration-300 transition-all' />
                                <Lightbox imageUrls={quoteImageUrls} view={view} />
                                <span className='text-white absolute border-[1px] border-white backdrop-blur-lg text-sm lg:text-base px-2 lg:px-4 py-1 lg:py-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-100 group-hover:opacity-0 transition-all duration-300'>{view == 'mobile' ? 'Click' : "Hover"} me</span>
                                <div className='absolute w-11/12 h-5/6 top-0 group-hover:top-1/2 left-1/2 transform -translate-y-full group-hover:-translate-y-1/2 -translate-x-1/2 bg-white bg-opacity-50 flex  justify-center items-center gap-4 transition-all duration-300 z-10'>
                                    <a href="https://github.com/JamesCartar/random-quote-machine.git" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Source Code</a>
                                    <a href="https://randon-quote-machine.netlify.app" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Visit Website</a>
                                </div>
                            </div>
                            <div className='dark:text-gray-200 flex-wrap justify-around gap-y-2 gap-2 items-center hidden md:flex lg:hidden py-2' aria-label='used technologies'>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Html</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Css</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Bootstrap</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>JavaScript</span>
                            </div >
                        </div>
                        <div className='col-span-4 flex flex-col justify-center items-start p-0 md:px-5 mt-5 md:mt-0 order-3 md:order-2'>
                            <a href="https://randon-quote-machine.netlify.app" target='_blank' className='flex justify-start items-center gap-1 text-blue-600 dark:text-thirdary group'>
                                <h3 className='selection:text-black text-xl font-mono font-bold'>{t('projects.project6.title')}</h3>
                                <FiArrowUpRight className='group-hover:translate-x-[2px] group-hover:-translate-y-[2px] transition-all' />
                            </a>
                            <p className='mt-3 text-[15px] dark:text-gray-100'>{t('projects.project6.desc')}</p>
                            <Rating projectNo={6} projectRating={ratings[5]?.rate} />
                            <div className='flex-wrap gap-2 items-center mt-4 justify-between flex md:hidden lg:flex' aria-label='used technologies'>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Html</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Css</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Bootstrap</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>JavaScript</span>
                            </div >
                        </div>
                        <div className='col-span-1 order-4'></div>
                    </div>
                </div>
                <div className={`flex-col justify-center items-center gap-9 md:gap-20 overflow-hidden transition-all duration-100 ${moreProject.second.open ? 'flex h-auto' : 'hidden h-0'}`}>
                    <div className='grid grid-cols-1 md:grid-cols-10 gap-2'>
                        <div className='col-span-1'></div>
                        <div className='col-span-4 group mt-3 md:mt-0'>
                            <div className='relative group overflow-hidden'>
                                <img alt={t('projects.project7.alt')} src={markdownF2} className='group-hover:scale-105 duration-300 transition-all' />
                                <Lightbox imageUrls={markdownImageUrls} view={view} />
                                <span className='text-white absolute border-[1px] border-white backdrop-blur-lg text-sm lg:text-base px-2 lg:px-4 py-1 lg:py-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-100 group-hover:opacity-0 transition-all duration-300'>{view == 'mobile' ? 'Click' : "Hover"} me</span>
                                <div className='absolute w-11/12 h-5/6 top-0 group-hover:top-1/2 left-1/2 transform -translate-y-full group-hover:-translate-y-1/2 -translate-x-1/2 bg-white bg-opacity-50 flex  justify-center items-center gap-4 transition-all duration-300 z-10'>
                                    <a href="https://github.com/JamesCartar/markdown-previewer.git" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Source Code</a>
                                    <a href="https://jc-markdown-previewer.netlify.app" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Visit Website</a>
                                </div>
                            </div>
                            <div className='dark:text-gray-200 flex-wrap justify-around gap-y-2 gap-2 items-center hidden md:flex lg:hidden py-2' aria-label='used technologies'>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Html</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Css</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Sass</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Bootstrap</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>JavaScript</span>
                            </div >
                        </div>
                        <div className='col-span-4 flex flex-col justify-center items-start p-0 md:px-5 mt-5 md:mt-0'>
                            <a href="https://jc-markdown-previewer.netlify.app" target='_blank' className='flex justify-start items-center gap-1 text-blue-600 dark:text-thirdary group'>
                                <h3 className='selection:text-black text-xl font-mono font-bold'>{t('projects.project7.title')}</h3>
                                <FiArrowUpRight className='group-hover:translate-x-[2px] group-hover:-translate-y-[2px] transition-all' />
                            </a>
                            <p className='mt-3 text-[15px] dark:text-gray-100'>{t('projects.project7.desc')}</p>
                            <Rating projectNo={7} projectRating={ratings[6]?.rate} />
                            <div className='flex-wrap gap-2 items-center mt-4 justify-between flex md:hidden lg:flex' aria-label='used technologies'>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Html</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Css</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Sass</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>Bootstrap</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2'>JavaScript</span>
                            </div >
                        </div>
                        <div className='col-span-1'></div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-10 gap-2'>
                        <div className='col-span-1 order-1'></div>
                        <div className='col-span-4 group mt-3 md:mt-0 order-2 md:order-3'>
                            <div className='relative group overflow-hidden'>
                                <img alt={t('projects.project8.alt')} src={calculatorF2} className='group-hover:scale-105 duration-300 transition-all' />
                                <Lightbox imageUrls={calculatorImageUrls} view={view} />
                                <span className='text-white absolute border-[1px] border-white backdrop-blur-lg text-sm lg:text-base px-2 lg:px-4 py-1 lg:py-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-100 group-hover:opacity-0 transition-all duration-300'>{view == 'mobile' ? 'Click' : "Hover"} me</span>
                                <div className='absolute w-11/12 h-5/6 top-0 group-hover:top-1/2 left-1/2 transform -translate-y-full group-hover:-translate-y-1/2 -translate-x-1/2 bg-white bg-opacity-50 flex  justify-center items-center gap-4 transition-all duration-300 z-10'>
                                    <a href="https://github.com/JamesCartar/react-calculator.git" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Source Code</a>
                                    <a href="https://jc-react-redux-calculator.netlify.app" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Visit Website</a>
                                </div>
                            </div>
                            <div className='dark:text-gray-200 flex-wrap justify-around gap-y-2 gap-2 items-center hidden md:flex lg:hidden py-2' aria-label='used technologies'>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px] whitespace-nowrap'>React</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px] whitespace-nowrap'>Redux</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px] whitespace-nowrap'>Css</span>
                            </div >
                        </div>
                        <div className='col-span-4 flex flex-col justify-center items-start p-0 md:px-5 mt-5 md:mt-0 order-3 md:order-2'>
                            <a href="https://jc-react-redux-calculator.netlify.app" target='_blank' className='flex justify-start items-center gap-1 text-blue-600 dark:text-thirdary group'>
                                <h3 className='selection:text-black text-xl font-mono font-bold'>{t('projects.project8.title')}</h3>
                                <FiArrowUpRight className='group-hover:translate-x-[2px] group-hover:-translate-y-[2px] transition-all' />
                            </a>
                            <p className='mt-3 text-[15px] dark:text-gray-100'>{t('projects.project8.desc')}</p>
                            <Rating projectNo={8} projectRating={ratings[7]?.rate} />
                            <div className='flex-wrap gap-2 items-center mt-4 justify-between flex md:hidden lg:flex' aria-label='used technologies'>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>React</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Redux</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Css</span>
                            </div >
                        </div>
                        <div className='col-span-1 order-4'></div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-10 gap-2'>
                        <div className='col-span-1'></div>
                        <div className='col-span-4 group mt-3 md:mt-0'>
                            <div className='relative group overflow-hidden'>
                                <img alt={t('projects.project9.alt')} src={gameF2} className='group-hover:scale-105 duration-300 transition-all' />
                                <Lightbox imageUrls={gameImageUrls} view={view} />
                                <span className='text-white absolute border-[1px] border-white backdrop-blur-lg text-sm lg:text-base px-2 lg:px-4 py-1 lg:py-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-100 group-hover:opacity-0 transition-all duration-300'>{view == 'mobile' ? 'Click' : "Hover"} me</span>
                                <div className='absolute w-11/12 h-5/6 top-0 group-hover:top-1/2 left-1/2 transform -translate-y-full group-hover:-translate-y-1/2 -translate-x-1/2 bg-white bg-opacity-50 flex  justify-center items-center gap-4 transition-all duration-300 z-10'>
                                    <a href="https://github.com/JamesCartar/role-playing-game.git" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Source Code</a>
                                    <a href="https://astonishing-malabi-d25fb9.netlify.app" target='_blank' className='border-2 border-gray-600 bg-primary text-black font-bold font-mono max-[470px]:px-2 max-[470px]:py-[2px] max-[470px]:text-[12px] text-base md:text-sm lg:text-base px-4 py-2 md:px-2 lg:px-4 md:py-1 lg:py-2 rounded-sm'>Visit Website</a>
                                </div>
                            </div>
                            <div className='dark:text-gray-200 flex-wrap justify-around gap-y-2 gap-2 items-center hidden md:flex lg:hidden py-2' aria-label='used technologies'>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Html</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>Css</span>
                                <span className='text-[12px]  flex-1 px-2 py-1 text-center border-[1px]'>JavaScript</span>
                            </div >
                        </div>
                        <div className='col-span-4 flex flex-col justify-center items-start p-0 md:px-5 mt-5 md:mt-0'>
                            <a href="https://astonishing-malabi-d25fb9.netlify.app" target='_blank' className='flex justify-start items-center gap-1 text-blue-600 dark:text-thirdary group'>
                                <h3 className='selection:text-black text-xl font-mono font-bold'>{t('projects.project9.title')}</h3>
                                <FiArrowUpRight className='group-hover:translate-x-[2px] group-hover:-translate-y-[2px] transition-all' />
                            </a>
                            <p className='mt-3 text-[15px] dark:text-gray-100'>{t('projects.project9.desc')}</p>
                            <Rating projectNo={9} projectRating={ratings[8]?.rate} />
                            <div className='flex-wrap gap-2 items-center mt-4 justify-between flex md:hidden lg:flex' aria-label='used technologies'>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Html</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>Css</span>
                                <span className=' dark:text-gray-100 text-[12px] border-[1px] border-gray-300 md:rounded-none flex-1 text-center py-[2px] px-4 md:px-2 whitespace-nowrap'>JavaScript</span>
                            </div >
                        </div>
                        <div className='col-span-1'></div>
                    </div>
                </div>
            </div>
            <div className={`flex justify-center items-center gap-3 mt-12`}>
                { moreProject.first.open && <button className='text-black dark:text-white border-2 border-gray-800 dark:border-gray-400 font-thin sm:font-bold px-3 py-1 sm:px-5 sm:py-2 text-sm sm:text-base rounded-full hover:bg-gray-300 dark:hover:bg-gray-700' onClick={handleShowLessAndScroll}>{t('projects.btns.showLess')}</button>}
                { !moreProject.first.open || !moreProject.second.open ? (
                    <button className=' border-2 border-gray-800 dark:border-thirdary px-3 py-1 sm:px-5 sm:py-2 text-sm sm:text-base rounded-full bg-gray-800 dark:bg-white dark:text-black font-thin sm:font-bold text-thirdary hover:bg-gray-700 dark:hover:bg-gray-300' onClick={handleShowMore}>{t('projects.btns.showMore')}</button>
                ) : null}
            </div>
        </div>
    )
}

export default FourthPage;