import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Collapse from "./collapse";
import { useMainContext } from "../context/mainContext";

const ThirdPage = () => {
	const {
		state: { language, t },
		handlePageChange,
		handleToTopShow,
	} = useMainContext();

	// useInView hook to detect if the icons are in view
	const { ref, inView } = useInView({
		triggerOnce: false, // Only trigger the animation once
		threshold: 0.2, // The percentage of the icon visible in the viewport to trigger the animation
	});

	useEffect(() => {
		if (inView) {
			handlePageChange("experiences");
			handleToTopShow(true);
		}
	}, [inView]);

	return (
		<div
			ref={ref}
			id="experiences"
			className="min-h-screen  selection:bg-primary selection:dark:bg-thirdary selection:dark:text-black dark:bg-gray-800 px-5 md:px-10 py-16 md:py-20 grid grid-cols-1 md:grid-cols-8  text-dark dark:text-gray-100"
		>
			<div className="col-span-4">
				<h2 className="selection:text-black text-primary drop-shadow-customlg border-s-[2px] border-e-thirdary border-s-secondary ms-[2px] md:ms-0 ps-5 text-3xl md:text-4xl capitalize mb-5">
					{t("experience.title")}
				</h2>
				<ul className="relative border-l border-gray-300 dark:border-gray-700 list-outside font-sans mt-10">
					<li className="mb-5 ml-4 relative">
						<div className="absolute w-3 h-3 bg-gray-200 dark:bg-thirdary rounded-full mt-1.5 -left-[22px] border border-white dark:border-gray-900"></div>
						<time className="selection:text-black mb-1 text-[13px] font-normal leading-none text-gray-400 dark:text-gray-500">
							{t("experience.firstExperience.date")}
						</time>
						<h3 className=" text-base md:text-lg text-gray-900 dark:text-white mt-2">
							{t("experience.firstExperience.position")}{" "}
							<span className="text-blue-700 dark:text-blue-500 text-base font-bold">
								@{" "}
								<a
									href="https://www.connected.com.mm"
									target="_blank"
								>
									{t("experience.firstExperience.company")}
								</a>
							</span>
						</h3>
						<div className="flex flex-col mt-2">
							<div className="flex justify-start items-start gap-3 md:ms-5 mt-2">
								<svg
									className={`w-7 h-7 lg:w-5 lg:h-5 text-gray-500 dark:text-gray-100 transition-transform rotate-180 `}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M5 12L15 19 15 5 5 12z"
									/>
								</svg>
								<p className=" text-[15px]">
									{t(
										"experience.firstExperience.responsibility"
									)}
								</p>
							</div>
							<div className="flex justify-start items-start gap-3 md:ms-5 mt-4">
								<svg
									className={`w-7 h-7 lg:w-5 lg:h-5 text-gray-500 dark:text-gray-100 transition-transform rotate-180 `}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M5 12L15 19 15 5 5 12z"
									/>
								</svg>
								<p className=" text-[15px]">
									{t(
										"experience.firstExperience.collaboration"
									)}
								</p>
							</div>
						</div>
					</li>
					<li className="mb-5 ml-4 relative">
						<div className="absolute w-3 h-3 bg-gray-200 dark:bg-thirdary rounded-full mt-1.5 -left-[22px] border border-white dark:border-gray-900"></div>
						<time className="selection:text-black mb-1 text-[13px] font-normal leading-none text-gray-400 dark:text-gray-500">
							{t("experience.secExperience.date")}
						</time>
						<h3 className="text-base md:text-lg text-gray-900 dark:text-white mt-2">
							{t("experience.secExperience.position")}{" "}
							<span className="text-blue-700 dark:text-blue-500 text-base font-bold">
								@{" "}
								<a
									href="https://www.facebook.com/thuma4future/"
									target="_blank"
								>
									{t("experience.secExperience.company")}
								</a>
							</span>
						</h3>
						<div className="flex flex-col mt-2">
							<div className="flex justify-start items-start gap-3 md:ms-5 mt-2">
								<svg
									className={`w-7 h-7 lg:w-5 lg:h-5 text-gray-500 dark:text-gray-400 transition-transform rotate-180 `}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M5 12L15 19 15 5 5 12z"
									/>
								</svg>
								<p className=" text-[15px]">
									{t(
										"experience.secExperience.responsibility"
									)}
								</p>
							</div>
							<div className="flex justify-start items-start gap-3 md:ms-5 mt-4">
								<svg
									className={`w-7 h-7 lg:w-5 lg:h-5 text-gray-500 dark:text-gray-400 transition-transform rotate-180 `}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M5 12L15 19 15 5 5 12z"
									/>
								</svg>
								<p className=" text-[15px]">
									{t(
										"experience.firstExperience.collaboration"
									)}
								</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div className="col-span-4 border-dotted w-full h-full flex justify-center items-center flex-col mt-6 md:mt-0 pb-5 md:pb-0">
				<h2
					className={`text-center font-serif capitalize text-base md:text-base px-2 ${
						language.value == "hi"
							? "max-[530px]:text-base"
							: "max-[530px]:text-[13px]"
					} border-b-2 border-black dark:border-white max-[530px]:border-primary max-[530px]:dark:border-thirdary pb-2 max-[530px]:mb-5 md:font-thin font-bold`}
				>
					{t("experience.secTitle")}
				</h2>
				<div className="w-[2px] h-7 bg-black dark:bg-white max-[530px]:hidden "></div>
				<div className=" w-11/12 lg:w-2/3 flex justify-between items-center ">
					<div className="flex-1 "></div>
					<div className="flex-1 "></div>
				</div>
				<div className="w-11/12 lg:w-2/3 flex max-[530px]:flex-col max-[530px]:items-center justify-between items-start max-[530px]:gap-3 gap-6 font-serif text-sm ">
					<div className="flex-1 text-center max-[530px]:mt-5 max-[530px]:w-full">
						<h3 className="bg-gray-200 dark:bg-gray-700 dark:text-white text-black  font-bold mb-5 py-2 border-s-2 border-secondary">
							Frontend
						</h3>
						<div className="flex flex-col gap-2">
							<div className="flex justify-between items-center gap-2">
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									Html
								</span>
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									Css
								</span>
							</div>
							<div className="flex justify-between items-center gap-2">
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									JavaScript
								</span>
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									Tailwind
								</span>
							</div>
							<div className="flex justify-between items-center gap-2">
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									Bootstrap
								</span>
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									MaterialUI
								</span>
							</div>
							<div className="flex justify-between items-center gap-2">
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									React
								</span>
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									Redux
								</span>
							</div>
							<div className="flex justify-between items-center gap-2">
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									JQuery
								</span>
							</div>
						</div>
					</div>
					<div className="flex-1 text-center max-[530px]:mt-5 max-[530px]:w-full">
						<h3 className="bg-gray-200 dark:bg-gray-700 dark:text-white text-black font-bold mb-5 py-2 border-e-2 border-secondary">
							Backend
						</h3>
						<div className="flex flex-col gap-2">
							<div className="flex justify-between items-center gap-2">
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									Node js
								</span>
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									Express
								</span>
							</div>
							<div className="flex justify-between items-center gap-2">
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									MongoDB
								</span>
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									Mongoose
								</span>
							</div>
							<div className="flex justify-between items-center gap-2">
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									Firebase
								</span>
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 flex-1">
									Socke IO
								</span>
							</div>
							<div className="flex flex-col justify-between items-center gap-2">
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 w-full">
									Java (learning)
								</span>
								<span className="border-2 dark:border-gray-700 rounded-lg shadow-sm py-1 w-full">
									Python (learning)
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ThirdPage;
