import React, { useEffect, useState } from "react";

import { useMainContext } from "../context/mainContext.js";
import ScrollAnimation from "./scorllAnimation.js";

import myImageCover from "../img/my-pf-cover1.png";
import myImage from "../img/my-pf1.png";
// icons
import { BiLogoFacebook } from "react-icons/bi";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { TfiLinkedin } from "react-icons/tfi";
import { FiGithub } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import SocialMediaIcons from "./socialMediaIcons.js";
import { Link } from "react-scroll";
import { useInView } from "react-intersection-observer";

const FirstPage = () => {
	const {
		state: { language, t },
		handlePageChange,
		handleToTopShow,
	} = useMainContext();
	const [isImgLoading, setIsImgLoading] = useState(true);

	// useInView hook to detect if the icons are in view
	const { ref, inView } = useInView({
		triggerOnce: false, // Only trigger the animation once
		threshold: 0.2, // The percentage of the icon visible in the viewport to trigger the animation
	});

	useEffect(() => {
		if (inView) {
			handlePageChange("home");
			handleToTopShow(false);
		}
	}, [inView]);

	const handleImageLoad = (event) => {
		// Image is loaded, and its height is accessible now
		const imageElement = event.target;
		const height = imageElement.height;

		setIsImgLoading(false);
	};

	const handleDownload = () => {
		const link = document.createElement("a");
		link.href = process.env.PUBLIC_URL + "/myCv.pdf";
		link.download = "myCv.pdf";
		link.click();
	};

	return (
		<div
			ref={ref}
			id="home"
			className=" selection:bg-primary selection:dark:bg-thirdary selection:dark:text-black min-h-screen bg-white dark:bg-gray-900 grid grid-cols-1 md:grid-cols-5 md:p-0 sm:pt-36 pt-28"
		>
			<div className="order-3 md:order-1 relative col-span-2 bg-gray-900 dark:bg-gray-800 items-center justify-center flex flex-col gap-8 pt-8 md:pt-3 pb-16 md:p-0">
				{/* <ScrollAnimation direction="top"> */}
				<div
					className={`group lg:max-w-sm md:max-w-[285px] min-h-96 mt-9 ${
						isImgLoading && "h-96"
					} relative rounded-full`}
				>
					<img
						loading="lazy"
						src={myImageCover}
						onLoad={handleImageLoad}
						className="lg:max-w-sm md:max-w-[285px] block rounded-full"
						alt={t("myPic.title")}
					/>
					<div
						className={`md:block hidden object-cover absolute transform -translate-y-1/2 top-1/2 left-0 w-0 overflow-hidden group-hover:w-full transition-all duration-700`}
					>
						<img
							src={myImage}
							className={`lg:max-w-sm md:max-w-[285px] block rounded-full`}
							alt={t("myPic.title")}
						/>
					</div>
				</div>
				{/* <SocialMediaIcons /> */}
				{/* <a href='https://github.com/JamesCartar' target='_blank' className='button-49 flex items-center justify-center gap-1' aria-label={t('myGithub.title')}>
          {t('myGithub.text')} <FiGithub /> 
        </a> */}
			</div>
			<div className="order-1 md:order-3 font-serif col-span-3 flex flex-col gap-8 lg:gap-10 justify-center items-start text-black dark:text-white md:ps-11 px-5 pb-10 md:pb-0">
				<div>
					<ScrollAnimation direction="left">
						<span
							className={`uppercase text-sm sm:text-sm ${
								language.value == "hi" && "text-base"
							}`}
						>
							{t("heroPara.greeting")}
						</span>
					</ScrollAnimation>
					<ScrollAnimation direction="left">
						<p
							className={`text-2xl sm:text-5xl mt-2 ${
								language.value == "hi" && "mt-3"
							}`}
						>
							{t("heroPara.im")}{" "}
							<span className="selection:text-black animate-text bg-gradient-to-r from-primary via-[#e4924f] to-primary bg-clip-text text-transparent text-4xl sm:text-5xl  font-black drop-shadow-customlg font-logo">
								{t("heroPara.name")}
							</span>
						</p>
					</ScrollAnimation>
					<ScrollAnimation direction="left">
						<p
							className={`text-base sm:text-lg max-w-xl font-mono mt-5 ${
								language.value == "hi" && "text-lg"
							}`}
						>
							{t("heroPara.im")} {t("heroPara.aFriendly")}{" "}
							<span className="capitalize text-xl sm:text-3xl font-serif font-bold text-gray-800 dark:text-thirdary">
								{t("heroPara.job")}
							</span>{" "}
							{t("heroPara.description")}
						</p>
					</ScrollAnimation>
				</div>
				<div
					className={`${
						language.value == "fr"
							? "text-sm md:text-[15px]"
							: " text-[14px] md:text-base"
					} flex gap-6 items-center font-serif`}
				>
					{/* <ScrollAnimation direction="bottom">
						<button
							className="button-82-pushable whitespace-nowrap"
							role="button"
							onClick={handleDownload}
						>
							<span className="button-82-shadow"></span>
							<span className="button-82-edge"></span>
							<span className="button-82-front text capitalize">
								{t("cv.text")}
							</span>
						</button>
					</ScrollAnimation>   */}
					<ScrollAnimation direction="bottom">
						<Link
							to="about"
							spy={true}
							smooth={true}
							offset={-70}
							duration={500}
							onClick={() => handlePageChange("about")}
						>
							<button
								className={`${
									language.value == "fr"
										? "text-sm md:text-[15px]"
										: " text-[14px] md:text-base"
								} whitespace-nowrap button-89 mb-1 font-bold dark:text-thirdary flex items-center gap-2 group`}
								aria-label={t("moreAboutMe.title")}
								role="button"
							>
								{t("moreAboutMe.text")}
								<BsArrowRight className="group-hover:animate-customMove" />
							</button>
						</Link>
					</ScrollAnimation>
				</div>
			</div>
		</div>
	);
};

export default FirstPage;
