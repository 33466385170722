import React, { useEffect, useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useMainContext } from "../context/mainContext";

function Rating({ projectNo, projectRating }) {
  const { handleRating }  = useMainContext();
  // initialize the rating state to 0
  const [rating, setRating] = useState(projectRating);
  // console.log('ssssssssssssssss: ', rating)

  // initialize the hovered state to 0
  const [hovered, setHovered] = useState(0);

  // define a function that updates the rating state based on the user's click
  const handleUserRating = (value) => {
    handleRating(projectNo, value)
    setRating(value);
  };

  // define a function that updates the hovered state based on the user's mouse enter
  const handleHover = (value) => {
    setHovered(value);
  };

  // define a function that resets the hovered state based on the user's mouse leave
  const handleLeave = () => {
    setHovered(0);
  };

  // define an array of stars to render
  const stars = [1, 2, 3, 4, 5];

  useEffect(() => {
    setRating(projectRating)
  }, [projectRating]);


  return (
    <div className="flex space-x-1 mt-3">
        {stars.map((star) => (
            <button
            key={star}
            onClick={() => handleUserRating(star)}
            onMouseEnter={() => handleHover(star)}
            onMouseLeave={handleLeave}
            className={`${
                rating >= star || hovered >= star
                ? "text-primary"
                : "text-gray-500"
            }`}
            >
            {
                rating >= star || hovered >= star
                ? <AiFillStar className="text-xl" />
                : <AiOutlineStar className="text-xl" />
            }
            
            </button>
        ))}
        <span className="text-sm ps-2 dark:text-gray-400">{rating} / 5</span>
    </div>
  );
}

export default Rating;
