import React, { useEffect, useRef, useState } from "react";

import { Link, animateScroll as scroll } from "react-scroll";

import { useMainContext } from "../context/mainContext";

import ukFlag from "../img/uk-flag.png";
import franceFlag from "../img/france-flag.png";
import italyFlag from "../img/italy-flag.png";
import indiaFlag from "../img/india-flag.png";

import {
	BsEmojiSunglassesFill,
	BsFillMoonFill,
	BsFillSunFill,
} from "react-icons/bs";
const genericHamburgerLine = ` h-[2px] w-6 my-[3px] rounded-full bg-black dark:bg-white transition ease transform duration-300`;

const Navbar = () => {
	const languageDropdownRef = useRef(null);
	const menuDropDownRef = useRef(null);

	const {
		state: {
			darkTheme,
			language,
			currentPage,
			languageDropDown,
			menuDropDown,
			t,
		},
		handleDarkTheme,
		handleLanguageChange,
		handlePageChange,
		handleLanguageDropDown,
		handleMenuDropDown,
	} = useMainContext();

	const handleOutsideClick = (event) => {
		if (
			languageDropdownRef.current &&
			!languageDropdownRef.current.contains(event.target)
		) {
			setTimeout(() => {
				handleLanguageDropDown({ drop: false });
			}, 200);
		}
		if (
			menuDropDownRef.current &&
			!menuDropDownRef.current.contains(event.target)
		) {
			setTimeout(() => {
				handleMenuDropDown({ drop: false });
			}, 200);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleOutsideClick);
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, []);

	const [flag, setFlag] = useState(() => {
		switch (language.value) {
			case "en":
				return ukFlag;
			case "fr":
				return franceFlag;
			case "it":
				return italyFlag;
			case "hi":
				return indiaFlag;
		}
	});

	useEffect(() => {
		switch (language.value) {
			case "en":
				setFlag(ukFlag);
				break;
			case "fr":
				setFlag(franceFlag);
				break;
			case "it":
				setFlag(italyFlag);
				break;
			case "hi":
				setFlag(indiaFlag);
				break;
		}
	}, [language.value]);

	return (
		<nav
			className={`selection:bg-primary selection:dark:bg-thirdary selection:text-black bg-white md:bg-black md:bg-opacity-70 shadow-md dark:bg-gray-900 fixed top-0 left-0 z-40 w-full h-auto`}
		>
			<div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 relative">
				<div
					className="flex items-center space-x-2 me-2 text-black md:text-white dark:text-white font-logo font-extrabold text-2xl"
					title="Hein Htet"
				>
					<span>H</span>
					<span className="text-primary">.</span>
					<span>H</span>
				</div>
				<div className="flex items-center md:order-2">
					<div className="relative">
						<button
							id="dropdown"
							ref={languageDropdownRef}
							type="button"
							onClick={() =>
								handleLanguageDropDown({
									drop: !languageDropDown.drop,
								})
							}
							data-dropdown-toggle="language-dropdown-menu"
							className="inline-flex items-center font-medium justify-center px-4 py-2 text-sm rounded-lg cursor-pointer focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-white text-black dark:text-white hover:bg-transparent dark:hover:text-white dark:md:hover:bg-white md:dark:hover:text-black md:text-white md:hover:bg-gray-800 font-serif"
							title={t("changeLanguage")}
							aria-label={t("changeLanguage")}
						>
							<img
								src={flag}
								className="w-[1.5rem] h-[1.5rem] object-cover rounded-full me-2"
							/>
							{t("language." + [language.value])}
						</button>
						<div
							className={`${
								languageDropDown.drop === true
									? "block"
									: "hidden"
							} z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 absolute right-0 top-full left-1/2 transform -translate-x-1/2 w-max`}
							id="language-dropdown-menu"
						>
							<ul className="py-2 font-medium" role="none">
								<li>
									<div
										className={` cursor-pointer ${
											language.value == "en" &&
											"text-secondary dark:text-thirdary"
										} block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-secondary dark:hover:text-thirdary dark:text-gray-400 dark:hover:bg-gray-600 font-serif`}
										role="menuitem"
										onClick={() =>
											handleLanguageChange({
												value: "en",
												text: "English (UK)",
											})
										}
									>
										<div className="inline-flex items-center">
											<img
												src={ukFlag}
												className="w-[1.5rem] h-[1.5rem] object-cover rounded-full me-2"
											/>
											{t("language.en")}
										</div>
									</div>
								</li>
								<li>
									<div
										className={` cursor-pointer ${
											language.value == "fr" &&
											"text-secondary dark:text-thirdary"
										} block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-secondary dark:hover:text-thirdary dark:text-gray-400 dark:hover:bg-gray-600 font-serif`}
										role="menuitem"
										onClick={() =>
											handleLanguageChange({
												value: "fr",
												text: "French",
											})
										}
									>
										<div className="inline-flex items-center">
											<img
												src={franceFlag}
												className="w-[1.5rem] h-[1.5rem] object-cover rounded-full me-2"
											/>
											{t("language.fr")}
										</div>
									</div>
								</li>
								<li>
									<div
										className={` cursor-pointer ${
											language.value == "it" &&
											"text-secondary dark:text-thirdary"
										} block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-secondary dark:hover:text-thirdary dark:text-gray-400 dark:hover:bg-gray-600 font-serif`}
										role="menuitem"
										onClick={() =>
											handleLanguageChange({
												value: "it",
												text: "Italiano",
											})
										}
									>
										<div className="inline-flex items-center">
											<img
												src={italyFlag}
												className="w-[1.5rem] h-[1.5rem] object-cover rounded-full me-2"
											/>
											{t("language.it")}
										</div>
									</div>
								</li>
								<li>
									<div
										className={` cursor-pointer ${
											language.value == "hi" &&
											"text-secondary dark:text-thirdary"
										} block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-secondary dark:hover:text-thirdary dark:text-gray-400 dark:hover:bg-gray-600 font-serif`}
										role="menuitem"
										onClick={() =>
											handleLanguageChange({
												value: "hi",
												text: "Hindi",
											})
										}
									>
										<div className="inline-flex items-center">
											<img
												src={indiaFlag}
												className="w-[1.5rem] h-[1.5rem] object-cover rounded-full me-2"
											/>
											{t("language.hi")}
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div className="items-center space-x-2 ms-2 hidden md:flex">
						<button
							className={`h-10 w-10 rounded-full flex items-center justify-center shadow transition-colors duration-300 ${
								darkTheme
									? "bg-white text-black"
									: "bg-black text-white"
							}`}
							onClick={handleDarkTheme}
							title={darkTheme ? t("mode.light") : t("mode.dark")}
						>
							{darkTheme ? (
								<BsEmojiSunglassesFill size={20} />
							) : (
								<BsFillMoonFill size={20} />
							)}
						</button>
					</div>

					<button
						ref={menuDropDownRef}
						type="button"
						className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-white dark:text-white"
						data-collapse-toggle="navbar-menu-item"
						onClick={() =>
							handleMenuDropDown({ drop: !menuDropDown.drop })
						}
						aria-controls="navbar-language"
						aria-expanded="false"
					>
						<span className="sr-only">Open main menu</span>
						<span
							className="flex flex-col h-10 w-10 rounded justify-center items-center group cursor-pointer"
							onClick={() =>
								handleMenuDropDown({ drop: !menuDropDown.drop })
							}
						>
							<div
								className={`${genericHamburgerLine} ${
									menuDropDown.drop === true
										? "rotate-45 translate-y-2 opacity-70 group-hover:opacity-100"
										: "opacity-80 group-hover:opacity-100"
								}`}
							/>
							<div
								className={`${genericHamburgerLine} ${
									menuDropDown.drop === true
										? "opacity-0"
										: "opacity-80 group-hover:opacity-100"
								}`}
							/>
							<div
								className={`${genericHamburgerLine} ${
									menuDropDown.drop === true
										? "-rotate-45 -translate-y-2 opacity-70 group-hover:opacity-100"
										: "opacity-80 group-hover:opacity-100"
								}`}
							/>
						</span>
					</button>
					{/* mobile menu items */}
					<div
						className={`${
							menuDropDown.drop === true ? "block" : "hidden"
						} transition-all absolute top-full w-11/12 right-0 left-1/2 transform -translate-x-1/2  z-50`}
						id="navbar-items"
					>
						<ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-100 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
							<Link
								to="home"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}
								onClick={() => handlePageChange("home")}
							>
								<button
									className={`${
										currentPage === "home"
											? "md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary"
											: " dark:text-white md:text-white"
									} w-full text-left relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary font-serif`}
									aria-current={`${
										currentPage == "home" && "page"
									}`}
								>
									{t("menu.home")}
									{currentPage === "home" && (
										<span className="hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary"></span>
									)}
								</button>
							</Link>
							<Link
								to="about"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}
								onClick={() => handlePageChange("about")}
							>
								<button
									className={`${
										currentPage === "about"
											? "md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary"
											: " dark:text-white md:text-white"
									} w-full text-left relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary font-serif`}
									aria-current={`${
										currentPage == "about" && "page"
									}`}
								>
									{t("menu.about")}
									{currentPage === "about" && (
										<span className="hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary"></span>
									)}
								</button>
							</Link>
							<Link
								to="experiences"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}
								onClick={() => handlePageChange("experiences")}
							>
								<button
									className={`${
										currentPage === "experiences"
											? "md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary"
											: " dark:text-white md:text-white"
									} w-full text-left relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary font-serif`}
									aria-current={`${
										currentPage == "experiences" && "page"
									}`}
								>
									{t("menu.experiences")}
									{currentPage === "experiences" && (
										<span className="hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary"></span>
									)}
								</button>
							</Link>
							<Link
								to="projects"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}
								onClick={() => handlePageChange("projects")}
							>
								<button
									className={`${
										currentPage === "projects"
											? "md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary"
											: " dark:text-white md:text-white"
									} w-full text-left relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary font-serif`}
									aria-current={`${
										currentPage == "projects" && "page"
									}`}
								>
									{t("menu.projects")}
									{currentPage === "projects" && (
										<span className="hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary"></span>
									)}
								</button>
							</Link>
							{/* <Link
								to="contact"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}
								onClick={() => handlePageChange("contact")}
							>
								<button
									className={`${
										currentPage === "contact"
											? "md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary"
											: " dark:text-white md:text-white"
									} w-full text-left relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary font-serif`}
									aria-current={`${
										currentPage == "contact" && "page"
									}`}
								>
									{t("menu.contact")}
									{currentPage === "contact" && (
										<span className="hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary"></span>
									)}
								</button>
							</Link> */}
							<li className="py-2 pl-3 pr-4 flex md:hidden border-t-2 border-gray-600 pt-3 mt-2">
								<span className="dark:text-white text-black">
									{t("mode.dark")}
								</span>
								<label className="relative inline-flex items-center cursor-pointer ml-auto">
									<input
										type="checkbox"
										checked={darkTheme ? true : false}
										value=""
										className="sr-only peer"
										onChange={() => handleDarkTheme()}
									/>
									<div
										className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-thirdary`}
									></div>
								</label>
							</li>
						</ul>
					</div>
				</div>
				{/* laptop menu item */}
				<div
					className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
					id="navbar-language"
				>
					<ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-100 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
						<Link
							to="home"
							spy={true}
							smooth={true}
							offset={-70}
							duration={500}
						>
							<button
								className={`${
									currentPage === "home"
										? "md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary"
										: " dark:text-white md:text-white"
								} relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary font-serif cursor-pointer `}
								aria-current="page"
							>
								{t("menu.home")}
								{currentPage === "home" && (
									<span className="hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary"></span>
								)}
							</button>
						</Link>
						<Link
							to="about"
							spy={true}
							smooth={true}
							offset={-70}
							duration={500}
						>
							<button
								className={`${
									currentPage === "about"
										? "md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary"
										: " dark:text-white md:text-white"
								} relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary font-serif cursor-pointer `}
							>
								{t("menu.about")}
								{currentPage === "about" && (
									<span className="hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary"></span>
								)}
							</button>
						</Link>
						<Link
							to="experiences"
							spy={true}
							smooth={true}
							offset={-70}
							duration={500}
						>
							<button
								className={`${
									currentPage === "experiences"
										? "md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary"
										: " dark:text-white md:text-white"
								} relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary font-serif cursor-pointer `}
							>
								{t("menu.experiences")}
								{currentPage === "experiences" && (
									<span className="hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary"></span>
								)}
							</button>
						</Link>
						<Link
							to="projects"
							spy={true}
							smooth={true}
							offset={-70}
							duration={500}
						>
							<button
								className={`${
									currentPage === "projects"
										? "md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary"
										: " dark:text-white md:text-white"
								} relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary font-serif cursor-pointer `}
							>
								{t("menu.projects")}
								{currentPage === "projects" && (
									<span className="hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary"></span>
								)}
							</button>
						</Link>
						{/* <Link to="contact" spy={true} smooth={true} offset={-70} duration={500} >
              <button className={`${currentPage === 'contact' ? 'md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary' : ' dark:text-white md:text-white'} relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary font-serif cursor-pointer `}>
                {t("menu.contact")}
                {currentPage === 'contact' && <span className="hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary"></span>}
              </button>
            </Link> */}
						<li className="py-2 pl-3 pr-4 flex md:hidden border-t-2 border-gray-600 pt-3">
							<span className="dark:text-white text-black">
								{t("mode.dark")}
							</span>
							<label className="relative inline-flex items-center cursor-pointer ml-auto">
								<input
									type="checkbox"
									checked={darkTheme ? true : false}
									value=""
									className="sr-only peer"
									onChange={() => handleDarkTheme()}
								/>
								<div
									className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-thirdary`}
								></div>
							</label>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;

// <nav className="bg-white md:bg-black md:bg-opacity-70 shadow-md dark:bg-gray-900 fixed top-0 left-0 z-50 w-full">
//   <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
//     <div title='Hein Htet' className='flex items-center space-x-2 me-2 text-black md:text-white dark:text-white font-logo font-extrabold text-2xl'>
//       <span>H</span>
//       <span className='text-primary'>.</span>
//       <span>H</span>
//     </div>
//     <div className="flex items-center md:order-2">
//         <button type="button" data-dropdown-toggle="language-dropdown-menu" className="inline-flex items-center font-medium justify-center px-4 py-2 text-sm rounded-lg cursor-pointer focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-white text-black dark:text-white hover:bg-transparent dark:hover:text-white dark:md:hover:bg-white md:dark:hover:text-black md:text-white md:hover:bg-gray-800">
//           <img src={flag} className='w-[1.5rem] h-[1.5rem] object-cover rounded-full me-2' />
//           {t("language."+[language.value])}
//         </button>
//         <div className="items-center space-x-2 ms-2 hidden md:flex">
//            <button
//              className={`h-10 w-10 rounded-full flex items-center justify-center shadow transition-colors duration-300 ${
//                darkTheme ? 'bg-white text-black' : 'bg-black text-white'
//              }`}
//              onClick={handleDarkTheme}
//              title={darkTheme ? t('mode.light') : t('mode.dark')}
//            >
//              {darkTheme ? <BsEmojiSunglassesFill size={20}  /> : <BsFillMoonFill size={20} />}
//            </button>
//         </div>
//         <div className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700" id="language-dropdown-menu">
//           <ul className="py-2 font-medium" role="none">
//             <li>
//               <a href="#" className={`${language.value == 'en' && 'text-secondary dark:text-thirdary'} block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-secondary dark:hover:text-thirdary dark:text-gray-400 dark:hover:bg-gray-600 font-serif`} role="menuitem">
//                 <div className="inline-flex items-center" onClick={() => handleLanguageChange({ value: "en", text: 'English (UK)'})}>
//                   <img src={ukFlag} className='w-[1.5rem] h-[1.5rem] object-cover rounded-full me-2' />
//                   {t("language.en")}
//                 </div>
//               </a>
//             </li>
//             <li>
//               <a href="#" className={`${language.value == 'fr' && 'text-secondary dark:text-thirdary'} block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-secondary dark:hover:text-thirdary dark:text-gray-400 dark:hover:bg-gray-600 font-serif`} role="menuitem">
//                 <div className="inline-flex items-center" onClick={() => handleLanguageChange({ value: 'fr', text: 'French'})}>
//                   <img src={franceFlag} className='w-[1.5rem] h-[1.5rem] object-cover rounded-full me-2' />
//                   {t("language.fr")}
//                 </div>
//               </a>
//             </li>
//             <li>
//               <a href="#" className={`${language.value == 'it' && 'text-secondary dark:text-thirdary'} block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-secondary dark:hover:text-thirdary dark:text-gray-400 dark:hover:bg-gray-600 font-serif`} role="menuitem">
//                 <div className="inline-flex items-center" onClick={() => handleLanguageChange({ value: 'it', text: 'Italiano'})}>
//                   <img src={italyFlag} className='w-[1.5rem] h-[1.5rem] object-cover rounded-full me-2' />
//                   {t("language.it")}
//                 </div>
//               </a>
//             </li>
//             <li>
//               <a href="#" className={`${language.value == 'hi' && 'text-secondary dark:text-thirdary'} block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-secondary dark:hover:text-thirdary dark:text-gray-400 dark:hover:bg-gray-600 font-serif`} role="menuitem">
//                 <div className="inline-flex items-center" onClick={() => handleLanguageChange({value: 'hi', text: 'Hindi'})}>
//                   <img src={indiaFlag} className='w-[1.5rem] h-[1.5rem] object-cover rounded-full me-2' />
//                   {t("language.hi")}
//                 </div>
//               </a>
//             </li>
//           </ul>
//         </div>
//         <button data-collapse-toggle="navbar-language" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-white dark:text-white" aria-controls="navbar-language" aria-expanded="false">
//           <span className="sr-only">Open main menu</span>
//           <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
//               <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
//           </svg>
//       </button>
//     </div>
//     <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-language">
//       <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-100 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
//         <li onClick={() => handlePageChange("home")} >
//           <a href="#" className={`${ currentPage === 'home' ? 'md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary' : ' dark:text-white md:text-white' } relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary`}  aria-current="page">
//             {t("menu.home")}
//             {currentPage === 'home' && <span className='hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary'></span>}
//           </a>
//         </li>
//         <li onClick={() => handlePageChange("about")} >
//           <a href="#" className={`${ currentPage === 'about' ? 'md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary' : ' dark:text-white md:text-white' } relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary`} >
//             {t("menu.about")}
//             {currentPage === 'about' && <span className='hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary'></span>}
//           </a>
//         </li>
//         <li onClick={() => handlePageChange("projects")} >
//           <a href="#" className={`${ currentPage === 'projects' ? 'md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary' : ' dark:text-white md:text-white' } relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary`} >
//             {t("menu.projects")}
//             {currentPage === 'projects' && <span className='hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary'></span>}
//           </a>
//         </li>
//         <li onClick={() => handlePageChange("contact")} >
//           <a href="#" className={`${ currentPage === 'contact' ? 'md:text-thirdary text-black bg-primary md:bg-transparent dark:bg-thirdary md:dark:bg-transparent md:dark:text-thirdary' : ' dark:text-white md:text-white' } relative block py-2 pl-3 pr-4 rounded md:p-0 text-black md:hover:text-thirdary`} >
//             {t("menu.contact")}
//             {currentPage === 'contact' && <span className='hidden md:block absolute left-0 -bottom-2 w-full h-1 bg-thirdary'></span>}
//           </a>
//         </li>
//         <li className='py-2 pl-3 pr-4 flex md:hidden border-t-2 border-gray-600 pt-3'>
//           <span className='dark:text-white text-black'>{t("mode.dark")}</span>
//           <label className="relative inline-flex items-center cursor-pointer ml-auto">
//             <input type="checkbox" checked={darkTheme ? true : false} value="" className="sr-only peer" onChange={() => handleDarkTheme()} />
//             <div className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-thirdary`}></div>
//           </label>
//         </li>
//       </ul>
//     </div>
//   </div>
// </nav>
