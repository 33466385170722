import React from "react";
import { useInView } from "react-intersection-observer";

const ScrollAnimation = ({ children, direction }) => {

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjust this value based on when you want the animation to trigger
  });

  let animationClasses = "opacity-0";
  if (inView) {
    animationClasses =
      "animate-fade-in " +
      (direction === "left"
        ? "animate-slideFromLeft"
        : direction === "right"
        ? "animate-slideFromRight"
        : direction === "top"
        ? "animate-slideFromTop"
        : "animate-slideFromBottom");
  }

  return <div ref={ref} className={animationClasses}>{children}</div>;
};

export default ScrollAnimation;